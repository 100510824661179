<script>
import TiptapEditor from '@shared/components/TiptapEditor.vue';
import VueSimpleEmbed from '@shared/components/VueSimpleEmbed.vue';
import AIContentTrainingBox from '@app/components/AIContentTrainingBox.vue';

export default {
  components: {
    TiptapEditor,
    VueSimpleEmbed,
  },
  head: {
    title: 'Description',
  },
  data() {
    return {
      isLoading: false,
      videoPreviewUrl: '',
      bundle: {
        summary: '',
        promotional_video_url: '',
        description: '',
        goals: '',
        target: '',
        requirements: '',
      },
    };
  },
  computed: {
    currentBundle() {
      return this.$store.state.bundles.current;
    },
    authStore() {
      return this.$store.getters['auth/store'];
    },
    pageURL() {
      let p = '';

      if (this.currentBundle.status === 'DRAFT') {
        p = `?p=${this.$store.state.auth.previewToken}`;
      }

      return `${this.$store.getters['auth/url']}/packs/${this.currentBundle.slug}${p}`;
    },
  },
  created() {
    Object.keys(this.bundle)
      .forEach((key) => (this.bundle[key] = this.currentBundle[key]));

    this.videoPreviewUrl = this.bundle.promotional_video_url;
  },
  methods: {
    showAIContentGenerator(type) {
      const modal = this.$buefy.modal.open({
        parent: this,
        canCancel: false,
        component: AIContentTrainingBox,
        width: 640,
        hasModalCard: true,
        props: {
          type,
        },
        events: {
          content: () => {
            // if (content.type === 'value_proposition') {
            //   this.bundle.summary = content.generated_content;
            // } else if (content.type === 'sales_page') {
            //   this.$refs.description.setValue(content.generated_content);
            // }

            modal.close();
          },
        },
      });
    },
    handle() {
      const bundle = { ...this.bundle };
      this.isLoading = true;
      this.$store.dispatch('bundles/update', {
        uuid: this.currentBundle.uuid,
        bundle,
        isCurrent: true,
      })
        .then(() => {
          this.videoPreviewUrl = this.bundle.promotional_video_url;
          this.$showMessage.success();
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<template>
  <div class="columns is-multiline">
    <div class="column is-4 content pr-10 is-small">
      <h2 class="is-size-5">
        Vidéo de présentation
      </h2>
      <p>
        Ajoutez une vidéo de présentation de votre page, à travers
        un lien <strong>YouTube</strong>, <strong>Vimeo</strong>,
        ou <strong>Dailymotion</strong>.
      </p>
      <p>Une vidéo de présentation rendra votre page plus dynamique !</p>
      <p class="has-text-weight-bold">
        Votre page est accessible en <a :href="pageURL" target="_blank">cliquant ici</a>.
      </p>
      <p>
        Ce champ est facultatif.
      </p>
    </div>

    <div class="column is-8">
      <form class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
        <b-field>
          <b-input type="url" pattern="https?://.+" placeholder="Lien vers la vidéo"
            v-model="bundle.promotional_video_url" />
        </b-field>

        <div v-if="videoPreviewUrl" class="field">
          <VueSimpleEmbed class="has-text-centered" :attrs="{
            class: 'mw-full mx-auto',
            width: '560',
            height: '315'
          }" :url="videoPreviewUrl">
            <b-notification type="is-danger" :closable="false">
              Il y a eu un problème de chargement...
            </b-notification>
          </VueSimpleEmbed>
        </div>

        <b-field class="has-text-right">
          <b-button type="is-primary" native-type="submit" :loading="isLoading">
            Mettre à jour
          </b-button>
        </b-field>
      </form>
    </div>

    <div class="column is-4 content pr-10 is-small">
      <h2 class="is-size-5">
        Description
      </h2>
      <p>
        Décrivez votre pack pour donner envie aux apprenants de l'acquérir.
      </p>
      <p class="has-text-weight-bold">
        Ce texte apparaîtra dans votre page
        accessible en <a :href="pageURL" target="_blank">cliquant ici</a>.
      </p>
      <p>
        Ce champ est facultatif.
      </p>
    </div>

    <div class="column is-8">
      <form class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
        <b-field>
          <template #label>
            <span class="flex-between-center" @click.prevent>
              Description courte (apparaît dans les listes)

              <b-button type="is-danger is-light" class="ml-3" icon-left="magic"
                @click="showAIContentGenerator('value_proposition')">
                Générer
              </b-button>
            </span>
          </template>
          <b-input type="textarea" placeholder="Décrivez la formation en une ou deux phrases maximum" maxlength="200"
            v-model="bundle.summary" />
        </b-field>

        <b-field class="mt-5">
          <template #label>
            <span class="flex-between-center" @click.prevent>
              Description longue (apparaît dans la fiche)

              <b-button type="is-danger is-light" class="ml-3" icon-left="magic"
                @click="showAIContentGenerator('sales_page')">
                Générer
              </b-button>
            </span>
          </template>

          <TiptapEditor ref="description" :value="bundle.description" :hidden="['h2']"
            @input="bundle.description = $event" />
        </b-field>

        <b-field class="has-text-right">
          <b-button type="is-primary" native-type="submit" :loading="isLoading">
            Mettre à jour
          </b-button>
        </b-field>
      </form>
    </div>

    <div class="column is-4 content pr-10 is-small">
      <h2 class="is-size-5">
        Objectifs
      </h2>
      <p>
        Décrivez les objectifs pédagogiques du pack.
        Soyez le plus factuel et précis possible, cet élément est décisif pour les apprenants.
      </p>
      <p class="has-text-weight-bold">
        Ce texte apparaîtra dans votre page
        accessible en <a :href="pageURL" target="_blank">cliquant ici</a>.
      </p>
      <p>
        Ce champs est facultatif.
      </p>
    </div>

    <div class="column is-8">
      <form class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
        <b-field>
          <TiptapEditor :value="bundle.goals" :hidden="['h2']" @input="bundle.goals = $event" />
        </b-field>

        <b-field class="has-text-right">
          <b-button type="is-primary" native-type="submit" :loading="isLoading">
            Mettre à jour
          </b-button>
        </b-field>
      </form>
    </div>

    <div class="column is-4 content pr-10 is-small">
      <h2 class="is-size-5">
        Public cible
      </h2>
      <p>
        Décrivez les publics cibles de votre pack.
        N'hésitez pas à le faire sous forme de liste, par exemple.
      </p>
      <p class="has-text-weight-bold">
        Ce texte apparaîtra dans votre page
        accessible en <a :href="pageURL" target="_blank">cliquant ici</a>.
      </p>
      <p>
        Ce champ est facultatif.
      </p>
    </div>

    <div class="column is-8">
      <form class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
        <b-field>
          <TiptapEditor :value="bundle.target" :hidden="['h2']" @input="bundle.target = $event" />
        </b-field>

        <b-field class="has-text-right">
          <b-button type="is-primary" native-type="submit" :loading="isLoading">
            Mettre à jour
          </b-button>
        </b-field>
      </form>
    </div>

    <div class="column is-4 content pr-10 is-small">
      <h2 class="is-size-5">
        Prérequis
      </h2>
      <p>
        Décrivez les prérequis nécessaires pour acquérir votre pack.
        Là aussi, n'hésitez pas à le faire sous forme de liste.
      </p>
      <p class="has-text-weight-bold">
        Ce texte apparaîtra dans votre page
        accessible en <a :href="pageURL" target="_blank">cliquant ici</a>.
      </p>
      <p>
        Ce champ est facultatif.
      </p>
    </div>

    <div class="column is-8">
      <form class="box" @submit.prevent="dataIsValid(isLoading) && handle()">
        <b-field>
          <TiptapEditor :value="bundle.requirements" :hidden="['h2']" @input="bundle.requirements = $event" />
        </b-field>

        <b-field class="has-text-right">
          <b-button type="is-primary" native-type="submit" :loading="isLoading">
            Mettre à jour
          </b-button>
        </b-field>
      </form>
    </div>
  </div>
</template>
